import './media.scss';
import { loadYoutubeApi } from '../../utilities/js/helper'; // eslint-disable-line

class Media {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-media',
            silentAttr: 'silent',
            fullscreenAttr: 'fullscreen',
            loopAttr: 'loop',
            maxLengthAttr: 'data-media-maxlength',
            offsetAttr: 'data-media-offset',
            rel: 'data-rel'
        };

        this.settings = Object.assign({}, settings, options);
        this.$media = element;
        this.$mediaVideo = this.$media.querySelector('[data-youtube]');
        this.trackingCategory = null;
        this.mediaOffset = 0;
        this.mediaMaxLength = 0;
    }

    initialize () {
        if (this.$mediaVideo !== null) {
            import('../../utilities/js/youtube-player').then(({ YoutubePlayer }) => {
                this.initYoutubePlayer(YoutubePlayer);
            });
        }
    }

    initYoutubePlayer (YoutubePlayer) {
        const $video = this.$mediaVideo;
        let silent = false;
        let fullscreen = false;
        let loop = false;
        let rel = 0;

        if ($video.getAttribute(this.settings.silentAttr) !== null) {
            silent = true;
        }

        if ($video.getAttribute(this.settings.fullscreenAttr) !== null) {
            fullscreen = true;
        }

        if ($video.getAttribute(this.settings.loopAttr) !== null) {
            loop = true;
        }

        if (parseInt($video.getAttribute(this.settings.rel)) !== 0) {
            rel = 1;
        }

        if ($video.getAttribute(this.settings.offsetAttr) !== null) {
            this.mediaOffset = parseInt($video.getAttribute(this.settings.offsetAttr));
        }

        if ($video.getAttribute(this.settings.maxLengthAttr) !== null) {
            this.mediaMaxLength = parseInt($video.getAttribute(this.settings.maxLengthAttr));
        }

        if ($video !== null) {
            const videoPlayer = new YoutubePlayer($video, {
                silent: silent,
                fullscreen: fullscreen,
                loop: loop,
                offset: this.mediaOffset,
                rel: rel,
                maxLength: this.mediaMaxLength,
                onCall: () => {
                    this.$media.classList.add('show--video');
                },
                onStart: (videoId) => {
                    /*
                    if (this.trackingCategory !== null) {
                        sendTracking({
                            category: this.trackingCategory,
                            action: 'play',
                            label: 'youtubeId_' + videoId
                        });
                    }
                    */
                },
                onEnd: (videoId) => {
                    if (videoPlayer.player.video.isMuted() === false || videoPlayer.mute === 0) {
                        videoPlayer.destroy();
                        this.$media.classList.remove('show--video');
                    }
                    /*
                    if (this.trackingCategory !== null) {
                        sendTracking({
                            category: this.trackingCategory,
                            action: 'stop',
                            label: 'youtubeId_' + videoId
                        });
                    }
                    */
                },
                onPause: (videoId, progress) => {
                    /*
                    if (this.trackingCategory !== null) {
                        sendTracking({
                            category: this.trackingCategory,
                            action: 'pause',
                            label: progress + '%_youtubeId_' + videoId
                        });
                    }
                    */
                }
            });
        }
    }
}

export { Media };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    const $medias = $context.querySelectorAll('[data-media="root"]');

    if ($medias.length > 0) {
        /*
        loadYoutubeApi();
        window.onYouTubeIframeAPIReady = () => {
            $medias.forEach((media) => {
                const newMedia = new Media(media);
                newMedia.initialize();
            });
        };
        */
    }
});
